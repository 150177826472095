export const MEDIA_PATHS = {
  accoladesImageOneUrl: '/img/about/careers/accolades/accolades-9.png',
  accoladesImageTwoUrl: '/img/about/careers/accolades/accolades-8.png',
  accoladesImageThreeUrl: '/img/about/careers/accolades/accolades-10.png',
  benefitsImageOneUrl: '/img/about/careers/benefits-icons/drive-your-health-2-76x81.png',
  benefitsImageTwoUrl: '/img/about/careers/benefits-icons/steer-your-finances-80x81.png',
  benefitsImageThreeUrl: '/img/about/careers/benefits-icons/fuel-your-growth-80x81.png',
  expertsImageOneUrl: '/img/about/careers/9-square-placeholder.png',
  expertsVideoUrl: '/img/about/careers/animated-squares-7-compressed.mp4',
  expertsVideoTwoUrl: '/img/about/careers/animated-squares-6.webm',
  hrSmallBlueUrl: '/img/about/careers/hr-small-blue.png',
  quoteIconUrl: '/img/about/careers/quote.png',
  sliderImageOneUrl: '/img/about/careers/slider-pics/no-grid/birds-eye-3.jpg',
  sliderImageTwoUrl: '/img/about/careers/slider-pics/no-grid/backpack-event.jpg',
  sliderImageThreeUrl: '/img/about/careers/slider-pics/no-grid/edfest-6.jpg',
  sliderImageFourUrl: '/img/about/careers/slider-pics/no-grid/csr-event.jpg',
  sliderImageFiveUrl: '/img/about/careers/slider-pics/no-grid/bhm-event-2.jpg',
  sliderImageSixUrl: '/img/about/careers/slider-pics/no-grid/edfest-1.jpg',
  sliderImageSevenUrl: '/img/about/careers/slider-pics/no-grid/edfest-3.jpg',
  sliderImageEightUrl: '/img/about/careers/slider-pics/no-grid/edfest-5.jpg',
  sliderImageNineUrl: '/img/about/careers/slider-pics/no-grid/edfest-4.jpg',
  toolsImageOneUrl: '/img/about/careers/tools/tool1.png',
  toolsImageTwoUrl: '/img/about/careers/tools/tool2.png',
  toolsImageThreeUrl: '/img/about/careers/tools/tool3.png',
  toolsImageFourUrl: '/img/about/careers/tools/tool4.png',
  toolsImageFiveUrl: '/img/about/careers/tools/tool5.png',
  toolsImageSixUrl: '/img/about/careers/tools/tool6.png',
  toolsImageSevenUrl: '/img/about/careers/tools/tool7.png',
  trustImageOneUrl: '/img/about/careers/trust-icons/t.png',
  trustImageTwoUrl: '/img/about/careers/trust-icons/r.png',
  trustImageThreeUrl: '/img/about/careers/trust-icons/u.png',
  trustImageFourUrl: '/img/about/careers/trust-icons/s.png',
  trustImageFiveUrl: '/img/about/careers/trust-icons/t2.png',
  tipsDaveOwen: '/img/about/careers/employee-tips/dave-owen.png',
  tipsAshleeBuhler: '/img/about/careers/employee-tips/ashlee-buhler.png',
  tipsIlanaGershteyn: '/img/about/careers/employee-tips/ilana-gershteyn.png',
  tipsSergeiFokichev: '/img/about/careers/employee-tips/sergei-fokichev.png',
};
